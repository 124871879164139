import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Card, TitleWrap } from 'components/common';
import { Wrapper, Grid, Item, Content, CardBody, ComingSoon } from './styles';
import projects from './projects.json';

export const Projects = () => {
  const { theme } = useContext(ThemeContext);
  
  return (
    <Wrapper as={Container} id="projects">
      <h2>Projects</h2>
      <Grid>
        {projects.map((project, index) => (
          <Item
            key={index}
            as="a"
            target="_blank" rel="noopener noreferrer" theme={theme}>
            <Card theme={theme}>
              <CardBody>
                <Content>
                  <h4>{project.title}</h4>
                  <p>{project.description}</p>
                  <div align='center'>
                    <ul>
                      {project.technologies.map((techno, index) => (<li key={index}>{ techno }</li>))}
                    </ul>
                  </div>
                </Content>
                <div align='center'>
                  <iframe width="560" height="315" style={{ margin: 0 }} title={project.title} src={project.link} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
              </CardBody>
            </Card>
          </Item>)
        )}
      </Grid>
      <ComingSoon>
        <p>More Projects Coming Soon...</p>
        <p>Check <a href='https://www.linkedin.com/in/yassine-bouhouta-aa6511154/' target='_blank'>LinkedIn</a> for old projects.</p>
      </ComingSoon>
    </Wrapper>
  );
};