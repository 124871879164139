import styled from 'styled-components';

export const Wrapper = styled.div`
	padding: 2rem 0;
`;

export const Grid = styled.div`
	display: grid;
	align-items: center;
	grid-template-columns: repeat(1, 1fr);
	grid-template-rows: 8fr;
	gap: 1.2rem 1.2rem;
`;
/*
@media (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 680px) {
    grid-template-columns: 1fr;
  }
*/

export const Item = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
	box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.11);

	h4 {
		color: ${({ theme }) => (theme === 'light' ? '#212121' : '#fff')};
	}

	p {
		color: ${({ theme }) => (theme === 'light' ? '#707070' : '#c7c7c7')};
	}
`;

export const CardBody = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 0.5rem;
	align-items: center;
	@media (max-width: 1024px) {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
	}
`;

export const Content = styled.div`
	padding: 1rem 0;
	ul {
		list-style-type: none;
	}
`;

export const ComingSoon = styled.div`
	text-align: center;
	color: yellow;
	margin: 1.5rem;
	font-weight: bold;
	font-size: 1.7rem;
	p {
		&:last-child {
			font-size: 1.2rem;
		}
	}
	a {
		color: yellow;
	}
	a:hover {
		text-decoration: underline;
	}
`;

// export const Stats = styled.div`
// 	display: flex;
// 	align-items: center;

// 	div {
// 		display: flex;
// 		&:first-child {
// 			margin-right: 0.5rem;
// 		}

// 		img {
// 			margin: 0;
// 		}

// 		svg path {
// 			fill: ${({ theme }) => (theme === 'light' ? '#000' : '#fff')};
// 		}

// 		span {
// 			color: ${({ theme }) => (theme === 'light' ? '#000' : '#fff')};
// 			margin-left: 0.5rem;
// 		}
// 	}
// `;

// export const Languages = styled.div`
// 	opacity: 0.5;
// 	font-size: 14px;
// `;
